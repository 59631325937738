import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import { LimitLeftData } from "../../store/actions/RegistrationActions";

interface DownloadDBButtonProps {
  disabled?: boolean;
  getDatabase: () => void;
  limitsLeft?: LimitLeftData;
  monetisationOn?: boolean;
}

const DownloadDBButton: React.FC<DownloadDBButtonProps> = ({ disabled, getDatabase, limitsLeft, monetisationOn }) => {
  const { t } = useTranslation();

  let styleButton = {};
  let styleDivWrapper = {};
  if (disabled) {
    styleButton = { pointerEvents: "none" };
    styleDivWrapper = { display: "inline-block", cursor: "not-allowed" };
  }

  let button = (
    <div style={styleDivWrapper}>
      <button
        className="btn border bg-color-white txt-color-dusty-orange"
        disabled={disabled}
        type="button"
        onClick={getDatabase}
        style={styleButton}>
        <i className="fas fa-download mr-3"></i>
        {t("downloadDatabase")}
      </button>
    </div>
  );
  if (monetisationOn) {
    let text: string | undefined;
    if (limitsLeft?.limitApiCsv === false) {
      text = t("catalogPage.DBButton.freeTier");
    } else if (limitsLeft?.limitApiCsv !== undefined && limitsLeft?.limitApiCsv <= 0) {
      text = t("catalogPage.DBButton.noLimitLeft");
    }

    if (text) {
      button = (
        <Tooltip tooltipText={text}>
          <div>{button}</div>
        </Tooltip>
      );
    }
  }
  return <>{button}</>;
};

export default DownloadDBButton;
