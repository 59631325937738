import _ from "lodash";
import React, { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";

import TablePagination from "components/tablePagination/TablePagination";
import VerifyByGs1Icon from "components/VerifyIcon/VerifyByGs1Icon";
import { FullSearchParams } from "pages/CatalogPage/interfaces";
import queryString from "query-string";
import { toInt } from "validator";
import useAuthentication from "../../hooks/useAuthentication";
import { hasLimitLeft } from "../../utils/permissions";
import DownloadDBButton from "../CatalogPage/DownloadDBButton";

interface CatalogTableProps {
  data: Catalog[];
  numAllRecords: number;
  setInputParams: React.Dispatch<React.SetStateAction<FullSearchParams>>;
  inputParams: FullSearchParams;
  currentPage: number;
  maxPages: number;
  getDatabase: () => void;
}
// Object to create type and array for table
const Catalog = {
  id: "",
  name: "",
  company: { name: "" },
  gtinNumber: "",
  linkToPhoto: "",
};

type Catalog = typeof Catalog;

const CatalogTable: React.FC<CatalogTableProps> = ({
  data,
  numAllRecords,
  inputParams,
  setInputParams,
  currentPage,
  maxPages = 1000,
  getDatabase,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const searchProps = useMemo(() => queryString.parse(history.location.search), [history.location.search]);

  /**
   * Updates page state
   * @param page
   */
  function updatePageState(page: number) {
    const offset = inputParams.limit * page;
    setInputParams((prevState) => ({ ...prevState, offset }));

    history.push({
      pathname: "/catalog",
      search: queryString.stringify({ ...searchProps, offset }),
    });
  }

  const columns = [
    {
      id: "gtinNumber",
      accessor: ({ gtinNumber, isVerified }: any) => (
        <GtinNumberLabel gtinNumber={gtinNumber} isVerified={isVerified} />
      ),
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("catalog.table.gtin"),
      width: 175,
    },
    {
      id: "name",
      accessor: (data: any) => data?.name || "-",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("catalog.table.name"),
      width: 250,
    },
    {
      id: "brand",
      accessor: (data: any) => data?.brand || "-",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("catalog.table.mark"),
      width: 175,
    },
    {
      id: "categoryDetails",
      accessor: (data: any) =>
        data.isPublic ? data?.categoryDetails[data.categoryDetails.length - 1]?.text || "-" : "-",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("catalog.table.category"),
      width: 250,
    },
    {
      id: "photo",
      accessor: (data: any) =>
        data?.imageUrls?.length ? <img height="16" width="16" src="/catalog_image.png" /> : "-",
      headerClassName: "text-wrap box-shadow-none",
      Header: () => t("catalog.table.picture"),
      width: 80,
    },
    {
      id: "action",
      accessor: (data: any) => (
        <div className="d-flex justify-content-end pr-2">
          <button onClick={() => history.push(`/catalog/${data.gtinNumber}`)} className="btn white">
            {t("catalog.table.look")}
          </button>
        </div>
      ),
      headerClassName: "text-wrap box-shadow-none",
      Header: () => "",
      width: 170,
    },
  ];
  const { activeMembership } = useAuthentication();
  const dbButtonDisabled = !hasLimitLeft(activeMembership!);

  const tablePagination = (paginationProps: PropsWithChildren<any>) => (
    <div className="catalog-table__pagination-wrapper">
      <TablePagination wrapperClass="catalog-table__pagination-inner" {...paginationProps} maxPages={maxPages} />
      <DownloadDBButton
        disabled={dbButtonDisabled}
        getDatabase={getDatabase}
        limitsLeft={activeMembership?.company?.limitsLeft}
        monetisationOn={activeMembership?.company?.monetizationSwitch}
      />
    </div>
  );

  return (
    <ReactTable
      columns={columns}
      data={data}
      manual={true}
      PaginationComponent={(props) => tablePagination(props)}
      showPagination={true}
      pageSize={data.length}
      defaultPageSize={toInt(`${inputParams.limit}`) || 15}
      pages={_.ceil(numAllRecords / inputParams.limit)}
      page={currentPage}
      resizable={false}
      className="catalog__table"
      sortable={false}
      onPageChange={updatePageState}
    />
  );
};

type VerifyProps = {
  gtinNumber: string;
  isVerified: boolean;
};

const GtinNumberLabel = ({ gtinNumber, isVerified }: VerifyProps) => {
  const gtin = gtinNumber || "-";
  return (
    <div className="catalog-table__gtin-number">
      {isVerified ? <VerifyByGs1Icon label={gtin} placement="right" rect /> : gtinNumber}
    </div>
  );
};

export default CatalogTable;
