import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Checkbox from "components/Inputs/Checkbox";
import useAuthentication from "hooks/useAuthentication";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { DATABASE_DOWNLOAD_ACCESS } from "utils/constants";
import { hasLimitLeft, haveAccess } from "utils/permissions";
import DownloadDBButton from "../../components/CatalogPage/DownloadDBButton";
import { GCPData } from "../../interfaces/GCPData";
import { Membership } from "../../interfaces/UserInterface";
import { generateCatalogSchema } from "./CatalogPageValidation";
import { useEffect, useState } from "react";

interface CatalogActionButtonsProps {
  isFormDirty: boolean;
  hasProducts: boolean;
  getDatabase: () => void;
  setGcpData: React.Dispatch<GCPData | null | string>;
  isTermsAccepted: boolean;
  setTermsAccepted: (isTermsAccepted: boolean) => void;
}

const CatalogPageActionButtons: React.FC<CatalogActionButtonsProps> = ({
  isFormDirty,
  getDatabase,
  hasProducts,
  setGcpData,
  isTermsAccepted,
  setTermsAccepted,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation();
  const { user, activeMembership } = useAuthentication();
  const getActiveMembership: () => Membership | undefined | null = () => {
    let returnValue: Membership | undefined | null;
    returnValue = user?.memberships?.find((membership) => membership.id === localStorage.getItem("activeId"));
    if (!returnValue && user?.memberships) returnValue = user?.memberships[0];
    return returnValue;
  };
  const {
    formState: { isValid },
  } = useForm({
    validationSchema: generateCatalogSchema(t, setGcpData, executeRecaptcha, getActiveMembership()),
    reValidateMode: "onSubmit",
  });

  const dbButtonDisabled =
    !haveAccess(activeMembership!, DATABASE_DOWNLOAD_ACCESS) ||
    !hasLimitLeft(activeMembership!) ||
    ((!isFormDirty || !isValid) && !hasProducts);
  return (
    <>
      <div className="col-12 mt-3 d-flex flex-column justify-content-end align-items-center">
        <div style={{ width: "20%" }}>
          <button
            className={`btn primary border bg-color-white txt-color-dusty-orange px-4 mb-3 ${
              !isFormDirty || (!activeMembership && !isTermsAccepted) ? "disabled" : ""
            }`}
            data-testid="searchButton"
            type="submit"
            style={{ width: "100%" }}>
            {t("search")}
          </button>
        </div>
        <DownloadDBButton
          disabled={dbButtonDisabled}
          getDatabase={getDatabase}
          limitsLeft={activeMembership?.company?.limitsLeft}
          monetisationOn={activeMembership?.company?.monetizationSwitch}
        />
      </div>
    </>
  );
};

export default CatalogPageActionButtons;
