import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import CatalogPageInputsForm from "./CatalogPageInputsForm";
import CatalogPageModal from "./CatalogPageModal";
import TableOrPlaceholder from "./TableOrPlaceholder";
import { additionalDefaultSearchParams, defaultSearchParams, FullSearchParams } from "./interfaces";
import { setPendingStatus } from "store/actions/UiActions";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import useAuthentication from "hooks/useAuthentication";
import { generateDatabase, searchProductAdvance, searchProducts } from "store/thunk/CatalogThunk";
import { useHistory } from "react-router";
import CatalogBanner from "./CatalogBanner";
import { SelectedCategory, TableCategory, Category } from "interfaces/CategoryInterface";
import { GCPData } from "../../interfaces/GCPData";
import GCPValidationService from "../../services/GCPValidationService";
import { Membership } from "interfaces/UserInterface";
import { Product } from "../ProductPage/ProductPage";
import { usePrevious } from "../../hooks/usePrevious";
import * as DataThunk from "../../store/thunk/DataThunk";
import NPWSStatus from "../../utils/statuses";
import useMessage from "../../hooks/useMessageStatus";
import { mapCategories } from "../../utils/categories";
import { CompanyData } from "../../store/actions/RegistrationActions";
import { setActiveMembership } from "../../store/actions/AccountActions";
import handleReCaptchaVerify from "./recaptha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
const CatalogPage = () => {
  const GPC_MAIN_CATEGORIES_LIMIT = "100";
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, authenticated, activeMembership } = useAuthentication();
  const { setMessage } = useMessage();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const searchProps = useMemo(() => queryString.parse(history.location.search), [history.location.search]);
  const [inputParams, setInputParams] = useState<FullSearchParams>({
    ...defaultSearchParams,
    ...additionalDefaultSearchParams,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalWasShown, setModalWasShown] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [allProducts, setAllProducts] = useState<undefined | number>(undefined);
  const [requestSent, setRequestSent] = useState(false);
  const [gcpData, setGcpData] = useState<GCPData | null | string>(null);
  const [shouldHaveDefValues, setShouldHaveDefValues] = useState(false);
  const [categories, setCategories] = useState<TableCategory[]>([]);
  const [company, setCompany] = useState<CompanyData | undefined>(undefined);
  const prevSearchProps = usePrevious(searchProps);
  const { t } = useTranslation();

  const addZerosToGtin = (gtin: string) => gtin.padStart(14, "0");

  function mapCategory(categories: Category[]): TableCategory[] {
    return categories.map(({ text, id, children, code }: Category) => {
      return {
        key: id,
        isLeaf: !children.length,
        title: `${code} - ${text}`,
        code: code,
        children: children.length === 0 ? [] : mapCategory(children),
        checked: false,
      };
    });
  }

  async function getCategories() {
    try {
      const { data }: any = await dispatch(DataThunk.getCategories(GPC_MAIN_CATEGORIES_LIMIT));
      const mappedData = mapCategory(data.results);
      setCategories(mappedData);
    } catch (err) {
      setMessage(NPWSStatus.GENERIC_ERROR);
    }
  }
  function getInitialKeys(categories: TableCategory[], initialCategories: string[], keys: string[]) {
    for (const category of categories) {
      const isSelected = initialCategories.includes(category.code);

      if (isSelected) {
        if (category.isLeaf) {
          keys.push(category.key);
        } else {
          _.flattenDeep<TableCategory>(category.children.map(mapCategories))
            .filter((cat) => cat.isLeaf)
            .forEach((leaf) => keys.push(leaf.key));
        }
      } else {
        getInitialKeys(category.children, initialCategories, keys);
      }
    }
    return keys;
  }

  function selectInitialCategories() {
    const { gpc_number } = searchProps;
    const initialCategories = !!gpc_number ? (Array.isArray(gpc_number) ? gpc_number : [gpc_number]) : [];
    const categoriesToSelect = _.flattenDeep<TableCategory>(categories.map(mapCategories))
      .filter((cat) => initialCategories.includes(cat.code))
      .map<SelectedCategory>((cat) => ({
        key: cat.key,
        id: cat.code,
      }));
    const keysToSelect = getInitialKeys(categories, initialCategories, []);
    setSelectedCategories(categoriesToSelect);
    setSelectedKeys(keysToSelect);
  }

  /**
   * Clears out empty keys to not use them whilst searchnig in DB
   * @param params
   * @returns
   */
  function clearOutEmptyKeys(params: FullSearchParams) {
    const { offset, limit, ...searchableParams } = params;

    let newParams = { offset, limit };
    Object.entries(searchableParams).forEach((e) => {
      if (e[0] in params && !_.isEmpty(e[1])) {
        newParams = { ...newParams, ...Object.fromEntries([e]) };
      }
    });

    const paramsChanged = !_.isEqual(newParams, prevSearchProps);
    // if no prevSearchProps, offset shouldn't be reset (e.g. when navigating back from product)
    const offsetChanged = +(prevSearchProps?.offset || -1) !== +newParams.offset;

    return { ...newParams, offset: paramsChanged && !offsetChanged ? 0 : offset };
  }

  const onSearch = () => {
    let newParams = inputParams;

    /**
     * Maps nip and clears out all the "-" (hyphen sign)
     * @param nip
     * @returns
     */
    function mapNip(nip: string) {
      return nip.replace(/-/g, "");
    }

    if ("company_nip" in newParams) {
      newParams = { ...newParams, company_nip: mapNip(newParams.company_nip) };
    }

    setInputParams(newParams);
    const sanitizedUrlSearchParams = clearOutEmptyKeys(newParams);

    history.push({
      pathname: "/catalog",
      search: queryString.stringify(sanitizedUrlSearchParams),
    });
  };

  /**
   * Adds placeholder product
   * @param propsToSearchFor
   * @param [products]
   * @param count
   * @returns placeholder product
   */
  async function addPlaceholderProduct(
    propsToSearchFor: FullSearchParams,
    products: Product[] = [],
    count: number,
  ): Promise<{ products: Partial<Product>[]; count: number }> {
    if (count > 0) {
      return { products, count };
    }
    let fetchedGcpData;
    if (propsToSearchFor.gtin_number && !gcpData) {
      fetchedGcpData = await getGcpData(propsToSearchFor.gtin_number);
    }
    const shouldAddPlaceholder = propsToSearchFor.gtin_number && (fetchedGcpData || gcpData);

    return {
      products: shouldAddPlaceholder
        ? [
            GCPValidationService.createPlaceholderRecord(
              propsToSearchFor.gtin_number,
              (fetchedGcpData || gcpData) as GCPData,
              count,
              t,
            ),
          ]
        : products,
      count: shouldAddPlaceholder ? 1 : count,
    };
  }

  /**
   * Allows the user to search in DB if he/she is active member the search if more robust
   * @param activeMembership
   * @param propsToSearchFor
   */
  async function search(activeMembership: Membership | null, propsToSearchFor: FullSearchParams) {
    const handleSearchError = async (err: any) => {
      const { products, count } = await addPlaceholderProduct(propsToSearchFor, [], 0);
      setAllProducts(count);
      setProducts(products);
      dispatch(setPendingStatus(false));
      if (err.response && err.response.status == 429) {
        setMessage(NPWSStatus.TOO_MANY_REQUESTS);
      }
    };

    const handleSearchSuccess = async (data: any) => {
      const { products, count } = await addPlaceholderProduct(propsToSearchFor, data.results, data.count);
      setAllProducts(count);
      setProducts(products);
      dispatch(setPendingStatus(false));
    };

    dispatch(setPendingStatus(true));
    try {
      let res: any;
      let newQueryProps = propsToSearchFor;
      let newGtin = propsToSearchFor.gtin_number;

      if (!_.isEmpty(propsToSearchFor.gtin_number)) {
        newGtin = addZerosToGtin(propsToSearchFor.gtin_number);
        newQueryProps = {
          ...newQueryProps,
          gtin_number: newGtin,
        };
      }

      const { gtin_number, offset, limit } = newQueryProps;
      const getAdvSearch = (!!activeMembership && (newQueryProps.name__contains !== ''
        || newQueryProps.gpc_number.length !== 0  || newQueryProps.brand__contains !== ''
        || newQueryProps.company_name__contains !== ''  ||  newQueryProps.name__contains !== '' || newQueryProps.company_nip !== ''));

      if (getAdvSearch) {
        res = await dispatch(
              searchProductAdvance(activeMembership, queryString.stringify(clearOutEmptyKeys(newQueryProps))),
            );
        }

      handleSearchSuccess(res.data);
    } catch (e) {
      handleSearchError(e);
    }

    setRequestSent(true);
  }

  /**
   * Gets gcp data
   * @param gtin
   * @returns
   */
  async function getGcpData(gtin: string) {
    await handleReCaptchaVerify(executeRecaptcha);
    const data = await GCPValidationService.searchForGCP(gtin.padStart(14, "0"));
    setGcpData(data);
    return data;
  }

  useEffect(() => {
    const asyncSearch = async (params: FullSearchParams) => {
      let res;
      try {
        res = await search(activeMembership, params);
        setInputParams(params);
        setShouldHaveDefValues(true);
      } catch (e) {
        console.error(e);
      }

      return res;
    };

    // Check if anything in url search
    if (!_.isEmpty(searchProps)) {
      asyncSearch({ ...defaultSearchParams, ...additionalDefaultSearchParams, ...searchProps });
    } else if (_.isEmpty(searchProps)) {
      setAllProducts(0);
      setProducts([]);
      setRequestSent(false);
    }
  }, [searchProps, activeMembership]);

  async function getDatabase() {
    const {
      brand__contains = "",
      company_name__contains = "",
      company_nip = "",
      gtin_number = "",
      name__contains = "",
    } = inputParams;
    const paddedGtin = _.isEmpty(gtin_number) ? "" : addZerosToGtin(gtin_number);
    if (activeMembership) {
      try {
        const generateDatabaseResponse = await dispatch(
          generateDatabase(activeMembership, {
            brand: brand__contains,
            company_name: company_name__contains,
            nip: company_nip,
            gtin: paddedGtin,
            product_name: name__contains,
            categories: selectedCategories.map((c) => c.key),
          }),
        );
        activeMembership.company = JSON.parse(JSON.stringify(generateDatabaseResponse)).data;
        setActiveMembership(activeMembership);
        setMessage(NPWSStatus.DATABASE_GENERATE_SUCESS);
      } catch (err) {
        if (err.response.status === 401) {
          activeMembership.company.limitsLeft = {
            freeLimit: false,
            limitApiCsv: false,
            limitverify: false,
          };
          setMessage(NPWSStatus.NO_LIMIT_FOR_GENERATE_DATABASE);
        } else if (err.response.status === 403 && err.response.data.error === "processing") {
          setMessage(NPWSStatus.DB_ALREADY_PROCESSING);
        } else {
          setMessage(NPWSStatus.GENERIC_ERROR);
        }
      }
    }
  }

  useEffect(() => {
    if (!_.isEmpty(searchProps)) {
      setRequestSent(false);
      setShouldHaveDefValues(false);
    } // this clears out the old inputs since they don't support hot reload in them :_;
    else {
      if (history.action === "POP") {
        setInputParams({ ...defaultSearchParams, ...additionalDefaultSearchParams });
        setRequestSent(true);
        setShouldHaveDefValues(false);
      } // case user returned to the initial page - clear out the inputs
      setTimeout(() => {
        setRequestSent(false);
        setShouldHaveDefValues(true);
      }, 0); // reresh inputs
    }
  }, [searchProps, history]);

  useEffect(() => {
    if (authenticated) {
      getCategories();
    }
  }, []);

  useEffect(() => {
    selectInitialCategories();
  }, [categories, searchProps]);

  return (
    <div className="container px-0 catalog-page" style={{ marginTop: "-1.5rem" }}>
      <div className="col-12">
        <CatalogBanner />
      </div>
      {!requestSent || shouldHaveDefValues ? (
        <CatalogPageInputsForm
          onSearch={onSearch}
          inputParams={inputParams}
          selectedKeys={selectedKeys}
          setInputParams={setInputParams}
          setShowModal={setShowModal}
          setModalWasShown={setModalWasShown}
          setGcpData={setGcpData}
          hasProducts={!!allProducts}
          getDatabase={getDatabase}
        />
      ) : null}
      <CatalogPageModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalWasShown={modalWasShown}
        setInputParams={setInputParams}
        inputParams={inputParams}
        categories={categories}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        setSelectedCategories={setSelectedCategories}
      />
      <TableOrPlaceholder
        productsAmount={products.length}
        products={products}
        requestSent={requestSent}
        allProducts={allProducts}
        page={inputParams.offset / 15}
        inputParams={inputParams}
        setInputParams={setInputParams}
        getDatabase={getDatabase}
      />
    </div>
  );
};

export default CatalogPage;
